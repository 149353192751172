"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var client_1 = require("@mysten/sui.js/client");
var transactions_1 = require("@mysten/sui.js/transactions");
var utils_1 = require("@mysten/sui.js/utils");
var BaseStreamClient_1 = require("../common/BaseStreamClient");
var types_1 = require("../common/types");
var constants_1 = require("./constants");
var types_2 = require("./types");
var utils_2 = require("./utils");
var wallet_1 = require("./wallet");
var utils_3 = require("../common/utils");
var constants_2 = require("../common/constants");
var SuiStreamClient = /** @class */ (function (_super) {
    __extends(SuiStreamClient, _super);
    function SuiStreamClient(clusterUrl, cluster, ids) {
        if (cluster === void 0) { cluster = types_1.ICluster.Mainnet; }
        var _this = _super.call(this) || this;
        _this.programId = (ids === null || ids === void 0 ? void 0 : ids.program) ? ids.program : constants_1.SUI_PROGRAM_IDS[cluster];
        _this.configId = (ids === null || ids === void 0 ? void 0 : ids.config) ? ids.config : constants_1.SUI_CONFIG_IDS[cluster];
        _this.feeTableId = (ids === null || ids === void 0 ? void 0 : ids.feeTable) ? ids.feeTable : constants_1.SUI_FEE_TABLE_IDS[cluster];
        _this.client = new client_1.SuiClient({ url: clusterUrl });
        return _this;
    }
    /**
     * Creates a new stream/vesting contract.
     */
    SuiStreamClient.prototype.create = function (streamData, _a) {
        var _b;
        var senderWallet = _a.senderWallet;
        return __awaiter(this, void 0, void 0, function () {
            var wallet, totalFee, _c, txb, _d, digest, events;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        wallet = new wallet_1.SuiWalletWrapper(senderWallet, this.client);
                        return [4 /*yield*/, this.getTotalFee({ address: (_b = streamData.partner) !== null && _b !== void 0 ? _b : wallet.address })];
                    case 1:
                        totalFee = _e.sent();
                        return [4 /*yield*/, this.generateCreateBlock(wallet.address, __assign(__assign({}, streamData), { recipients: [__assign({}, streamData)] }), totalFee)];
                    case 2:
                        _c = __read.apply(void 0, [_e.sent(), 1]), txb = _c[0];
                        return [4 /*yield*/, wallet.signAndExecuteTransactionBlock({
                                transactionBlock: txb,
                                options: { showEvents: true },
                            })];
                    case 3:
                        _d = _e.sent(), digest = _d.digest, events = _d.events;
                        return [2 /*return*/, {
                                ixs: [],
                                txId: digest,
                                metadataId: events[0].parsedJson.address,
                            }];
                }
            });
        });
    };
    /**
     * Creates multiple stream/vesting contracts.
     * We don't chain transactions in one Block so that one failed transaction does not revert all others.
     */
    SuiStreamClient.prototype.createMultiple = function (multipleStreamData, _a) {
        var _b, _c;
        var senderWallet = _a.senderWallet;
        return __awaiter(this, void 0, void 0, function () {
            var wallet, totalFee, _d, txb, firstIndex, txs, metadatas, metadataToRecipient, errors, _e, digest, events_1, effects_1, e_1, errorInfo_1;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        wallet = new wallet_1.SuiWalletWrapper(senderWallet, this.client);
                        return [4 /*yield*/, this.getTotalFee({
                                address: (_b = multipleStreamData.partner) !== null && _b !== void 0 ? _b : wallet.address,
                            })];
                    case 1:
                        totalFee = _f.sent();
                        return [4 /*yield*/, this.generateCreateBlock(wallet.address, multipleStreamData, totalFee)];
                    case 2:
                        _d = __read.apply(void 0, [_f.sent(), 2]), txb = _d[0], firstIndex = _d[1];
                        txs = [];
                        metadatas = [];
                        metadataToRecipient = {};
                        errors = [];
                        _f.label = 3;
                    case 3:
                        _f.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, wallet.signAndExecuteTransactionBlock({
                                transactionBlock: txb,
                                options: { showEffects: true, showEvents: true },
                            })];
                    case 4:
                        _e = _f.sent(), digest = _e.digest, events_1 = _e.events, effects_1 = _e.effects;
                        txs.push(digest);
                        if (effects_1.status.status === "failure") {
                            multipleStreamData.recipients.forEach(function (recipient) {
                                var _a;
                                errors.push({
                                    error: (_a = effects_1.status.error) !== null && _a !== void 0 ? _a : "Unknown error!",
                                    recipient: recipient.recipient,
                                });
                            });
                        }
                        else {
                            multipleStreamData.recipients.forEach(function (recipient, index) {
                                var metadataId = events_1[index].parsedJson.address;
                                metadatas.push(metadataId);
                                metadataToRecipient[metadataId] = recipient;
                            });
                        }
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _f.sent();
                        errorInfo_1 = (0, utils_2.extractSuiErrorInfo)((_c = e_1.toString()) !== null && _c !== void 0 ? _c : "Unknown error!");
                        multipleStreamData.recipients.forEach(function (recipient, index) {
                            var _a;
                            if (errorInfo_1.index === undefined ||
                                errorInfo_1.index < index + firstIndex ||
                                errorInfo_1.index >= multipleStreamData.recipients.length + firstIndex ||
                                errorInfo_1.index === index + firstIndex) {
                                errors.push({
                                    error: errorInfo_1.text,
                                    recipient: recipient.recipient,
                                    contractErrorCode: (_a = errorInfo_1.parsed) === null || _a === void 0 ? void 0 : _a.name,
                                });
                            }
                        });
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/, {
                            txs: txs,
                            metadatas: metadatas,
                            metadataToRecipient: metadataToRecipient,
                            errors: errors,
                        }];
                }
            });
        });
    };
    /**
     * Attempts withdrawing from the specified stream.
     */
    SuiStreamClient.prototype.withdraw = function (_a, _b) {
        var id = _a.id, _c = _a.amount, amount = _c === void 0 ? constants_2.WITHDRAW_AVAILABLE_AMOUNT : _c;
        var senderWallet = _b.senderWallet, tokenId = _b.tokenId;
        return __awaiter(this, void 0, void 0, function () {
            var wallet, txb, digest;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        wallet = new wallet_1.SuiWalletWrapper(senderWallet, this.client);
                        txb = new transactions_1.TransactionBlock();
                        txb.moveCall({
                            target: "".concat(this.programId, "::protocol::withdraw"),
                            typeArguments: [tokenId],
                            arguments: [
                                txb.object(id),
                                txb.object(this.configId),
                                txb.object(utils_1.SUI_CLOCK_OBJECT_ID),
                                txb.pure(amount.toString()),
                            ],
                        });
                        return [4 /*yield*/, wallet.signAndExecuteTransactionBlock({
                                transactionBlock: txb,
                            })];
                    case 1:
                        digest = (_d.sent()).digest;
                        return [2 /*return*/, {
                                ixs: [],
                                txId: digest,
                            }];
                }
            });
        });
    };
    /**
     * Attempts canceling the specified stream.
     */
    SuiStreamClient.prototype.cancel = function (cancelData, _a) {
        var senderWallet = _a.senderWallet, tokenId = _a.tokenId;
        return __awaiter(this, void 0, void 0, function () {
            var wallet, txb, digest;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        wallet = new wallet_1.SuiWalletWrapper(senderWallet, this.client);
                        txb = new transactions_1.TransactionBlock();
                        txb.moveCall({
                            target: "".concat(this.programId, "::protocol::cancel"),
                            typeArguments: [tokenId],
                            arguments: [
                                txb.object(cancelData.id),
                                txb.object(this.configId),
                                txb.object(utils_1.SUI_CLOCK_OBJECT_ID),
                            ],
                        });
                        return [4 /*yield*/, wallet.signAndExecuteTransactionBlock({
                                transactionBlock: txb,
                            })];
                    case 1:
                        digest = (_b.sent()).digest;
                        return [2 /*return*/, {
                                ixs: [],
                                txId: digest,
                            }];
                }
            });
        });
    };
    /**
     * Attempts changing the stream/vesting contract's recipient (effectively transferring the stream/vesting contract).
     */
    SuiStreamClient.prototype.transfer = function (transferData, _a) {
        var senderWallet = _a.senderWallet, tokenId = _a.tokenId;
        return __awaiter(this, void 0, void 0, function () {
            var wallet, txb, digest;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        wallet = new wallet_1.SuiWalletWrapper(senderWallet, this.client);
                        txb = new transactions_1.TransactionBlock();
                        txb.moveCall({
                            target: "".concat(this.programId, "::protocol::transfer"),
                            typeArguments: [tokenId],
                            arguments: [txb.object(transferData.id), txb.pure(transferData.newRecipient)],
                        });
                        return [4 /*yield*/, wallet.signAndExecuteTransactionBlock({
                                transactionBlock: txb,
                            })];
                    case 1:
                        digest = (_b.sent()).digest;
                        return [2 /*return*/, {
                                ixs: [],
                                txId: digest,
                            }];
                }
            });
        });
    };
    /**
     * Tops up stream account with specified amount.
     */
    SuiStreamClient.prototype.topup = function (topupData, _a) {
        var senderWallet = _a.senderWallet, tokenId = _a.tokenId;
        return __awaiter(this, void 0, void 0, function () {
            var wallet, txb, coins, stream, totalFee, coinObject, digest;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        wallet = new wallet_1.SuiWalletWrapper(senderWallet, this.client);
                        txb = new transactions_1.TransactionBlock();
                        return [4 /*yield*/, this.getAllCoins(wallet.address, tokenId)];
                    case 1:
                        coins = _b.sent();
                        return [4 /*yield*/, this.getOne({ id: topupData.id })];
                    case 2:
                        stream = _b.sent();
                        totalFee = (stream.partnerFeePercent + stream.streamflowFeePercent) / 100;
                        coinObject = this.splitCoinObjectForAmount(txb, topupData.amount, tokenId, coins, totalFee);
                        txb.moveCall({
                            target: "".concat(this.programId, "::protocol::topup"),
                            typeArguments: [tokenId],
                            arguments: [
                                txb.object(topupData.id),
                                txb.object(this.configId),
                                coinObject,
                                txb.gas,
                                txb.pure(topupData.amount.toString()),
                            ],
                        });
                        this.returnSplittedCoinObject(txb, tokenId, coins, coinObject);
                        return [4 /*yield*/, wallet.signAndExecuteTransactionBlock({
                                transactionBlock: txb,
                            })];
                    case 3:
                        digest = (_b.sent()).digest;
                        return [2 /*return*/, {
                                ixs: [],
                                txId: digest,
                            }];
                }
            });
        });
    };
    /**
     * Fetch stream data by its id (address).
     */
    SuiStreamClient.prototype.getOne = function (_a) {
        var id = _a.id;
        return __awaiter(this, void 0, void 0, function () {
            var response, content;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.client.getObject({
                            id: id,
                            options: {
                                showContent: true,
                            },
                        })];
                    case 1:
                        response = _b.sent();
                        if (!response.data) {
                            throw new Error("Contract with id ".concat(id, " could not be found!"));
                        }
                        content = response.data.content;
                        if (content.dataType !== "moveObject") {
                            throw new Error("Not a Move Object!");
                        }
                        return [2 /*return*/, new types_2.Contract(content.fields, "0x97e9a9fb1392e9785319f5512d0bfde6ecf7757b09c6de41cec89e798dd361f2::strmt::STRMT")];
                }
            });
        });
    };
    SuiStreamClient.prototype.get = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error("Get all method is not supported for Sui chain!");
            });
        });
    };
    /**
     * Attempts updating the stream auto withdrawal params and amount per period
     */
    SuiStreamClient.prototype.update = function (updateData, _a) {
        var senderWallet = _a.senderWallet, tokenId = _a.tokenId;
        return __awaiter(this, void 0, void 0, function () {
            var wallet, txb, digest;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        wallet = new wallet_1.SuiWalletWrapper(senderWallet, this.client);
                        txb = new transactions_1.TransactionBlock();
                        txb.moveCall({
                            target: "".concat(this.programId, "::protocol::update"),
                            typeArguments: [tokenId],
                            arguments: [
                                txb.object(updateData.id),
                                txb.object(this.configId),
                                txb.object(utils_1.SUI_CLOCK_OBJECT_ID),
                                txb.gas,
                                txb.pure(updateData.enableAutomaticWithdrawal !== undefined
                                    ? [updateData.enableAutomaticWithdrawal]
                                    : [], "vector<bool>"),
                                txb.pure(updateData.withdrawFrequency !== undefined
                                    ? [updateData.withdrawFrequency.toString()]
                                    : [], "vector<u64>"),
                                txb.pure(updateData.amountPerPeriod !== undefined ? [updateData.amountPerPeriod.toString()] : [], "vector<u64>"),
                            ],
                        });
                        return [4 /*yield*/, wallet.signAndExecuteTransactionBlock({
                                transactionBlock: txb,
                            })];
                    case 1:
                        digest = (_b.sent()).digest;
                        return [2 /*return*/, {
                                ixs: [],
                                txId: digest,
                            }];
                }
            });
        });
    };
    SuiStreamClient.prototype.extractErrorCode = function (err) {
        var _a, _b;
        var errorInfo = (0, utils_2.extractSuiErrorInfo)((_a = err.toString()) !== null && _a !== void 0 ? _a : "Unknown error!");
        return ((_b = errorInfo === null || errorInfo === void 0 ? void 0 : errorInfo.parsed) === null || _b === void 0 ? void 0 : _b.name) || null;
    };
    SuiStreamClient.prototype.getFees = function (_a) {
        var address = _a.address;
        return __awaiter(this, void 0, void 0, function () {
            var response, content, fields, fieldsResponse, partnerDynamicField, valueResponse, valueContent, valueFields;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.client.getObject({
                            id: this.feeTableId,
                            options: {
                                showContent: true,
                            },
                        })];
                    case 1:
                        response = _b.sent();
                        content = response.data.content;
                        if (content.dataType !== "moveObject") {
                            throw new Error("Not a Move Object!");
                        }
                        fields = content.fields;
                        return [4 /*yield*/, this.client.getDynamicFields({
                                parentId: fields.values.fields.id.id,
                            })];
                    case 2:
                        fieldsResponse = _b.sent();
                        partnerDynamicField = fieldsResponse.data.filter(function (item) { return item.name.value === address; })[0];
                        if (!partnerDynamicField) {
                            return [2 /*return*/, null];
                        }
                        return [4 /*yield*/, this.client.getObject({
                                id: partnerDynamicField.objectId,
                                options: {
                                    showContent: true,
                                },
                            })];
                    case 3:
                        valueResponse = _b.sent();
                        valueContent = valueResponse.data.content;
                        if (valueContent.dataType !== "moveObject") {
                            throw new Error("Not a Move Object!");
                        }
                        valueFields = valueContent.fields.value.fields;
                        return [2 /*return*/, {
                                streamflowFee: Number(valueFields.streamflow_fee) / 100,
                                partnerFee: Number(valueFields.partner_fee) / 100,
                            }];
                }
            });
        });
    };
    SuiStreamClient.prototype.getDefaultStreamflowFee = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, content, fields;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.getObject({
                            id: this.configId,
                            options: {
                                showContent: true,
                            },
                        })];
                    case 1:
                        response = _a.sent();
                        content = response.data.content;
                        if (content.dataType !== "moveObject") {
                            throw new Error("Not a Move Object!");
                        }
                        fields = content.fields;
                        return [2 /*return*/, Number(fields.streamflow_fee) / 100];
                }
            });
        });
    };
    /**
     * Returns StreamClient protocol program ID.
     */
    SuiStreamClient.prototype.getProgramId = function () {
        return this.programId;
    };
    /**
     * Utility function to generate Transaction Block to create streams
     */
    SuiStreamClient.prototype.generateCreateBlock = function (walletAddress, multipleStreamData, totalFee) {
        return __awaiter(this, void 0, void 0, function () {
            var coins, totalAmount, txb, coinObject, firstIndex;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAllCoins(walletAddress, multipleStreamData.tokenId)];
                    case 1:
                        coins = _a.sent();
                        totalAmount = multipleStreamData.recipients
                            .map(function (recipiient) { return recipiient.amount; })
                            .reduce(function (prev, current) { return current.add(prev); });
                        txb = new transactions_1.TransactionBlock();
                        coinObject = this.splitCoinObjectForAmount(txb, totalAmount, multipleStreamData.tokenId, coins, totalFee);
                        coins = [coins[0]];
                        firstIndex = null;
                        multipleStreamData.recipients.forEach(function (recipient) {
                            var result = txb.moveCall({
                                target: "".concat(_this.programId, "::protocol::create"),
                                typeArguments: [multipleStreamData.tokenId],
                                arguments: [
                                    txb.object(_this.configId),
                                    txb.object(_this.feeTableId),
                                    txb.object(utils_1.SUI_CLOCK_OBJECT_ID),
                                    coinObject,
                                    txb.gas,
                                    txb.pure(recipient.amount.toString()),
                                    txb.pure(multipleStreamData.period),
                                    txb.pure(recipient.amountPerPeriod.toString()),
                                    txb.pure(multipleStreamData.start),
                                    txb.pure(recipient.cliffAmount.toString()),
                                    txb.pure(multipleStreamData.cancelableBySender),
                                    txb.pure(multipleStreamData.cancelableByRecipient),
                                    txb.pure(multipleStreamData.transferableBySender),
                                    txb.pure(multipleStreamData.transferableByRecipient),
                                    txb.pure(multipleStreamData.canTopup),
                                    txb.pure(!!multipleStreamData.canPause),
                                    txb.pure(!!multipleStreamData.canUpdateRate),
                                    txb.pure(!!multipleStreamData.automaticWithdrawal),
                                    txb.pure(multipleStreamData.withdrawalFrequency || 0),
                                    txb.pure(recipient.name),
                                    txb.pure(recipient.recipient),
                                    txb.pure(multipleStreamData.partner || walletAddress),
                                ],
                            });
                            if (result.kind === "Result" && firstIndex === null) {
                                firstIndex = result.index;
                            }
                        });
                        this.returnSplittedCoinObject(txb, multipleStreamData.tokenId, coins, coinObject);
                        return [2 /*return*/, [txb, firstIndex]];
                }
            });
        });
    };
    /**
     * Returns all coins owned by a Wallet
     * @param walletAddress wallet address
     * @param coinType coin type
     * @returns Array of Coin Structs
     */
    SuiStreamClient.prototype.getAllCoins = function (walletAddress, coinType) {
        return __awaiter(this, void 0, void 0, function () {
            var coinsResponse, coins;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.getCoins({
                            owner: walletAddress,
                            coinType: coinType,
                        })];
                    case 1:
                        coinsResponse = _a.sent();
                        coins = coinsResponse.data;
                        _a.label = 2;
                    case 2:
                        if (!coinsResponse.hasNextPage) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.client.getCoins({
                                owner: walletAddress,
                                coinType: coinType,
                                cursor: coinsResponse.nextCursor,
                            })];
                    case 3:
                        coinsResponse = _a.sent();
                        coins.push.apply(coins, __spreadArray([], __read(coinsResponse.data), false));
                        return [3 /*break*/, 2];
                    case 4: return [2 /*return*/, coins];
                }
            });
        });
    };
    /**
     * Utility function to split Coin object and use splitted object for stream operations.
     * We do it like thid to mitigate risk of Transaction reverting in case Stream is created for Sui Coin object.
     * This way we can safely pass splitted Sui Coin object in Stream payload and use another object for gas/withdrawal fees.
     * @param txb current transanction block
     * @param amount minimal amount of coins requried
     * @param coinType coin type
     * @param coins array of owned coins of the same type
     * @returns Coin Object to use in a Move Call
     */
    SuiStreamClient.prototype.splitCoinObjectForAmount = function (txb, amount, coinType, coins, totalFee) {
        var coinObject = coinType === utils_1.SUI_TYPE_ARG ? txb.gas : txb.object(coins[0].coinObjectId);
        if (coins.length > 1) {
            txb.mergeCoins(coinObject, coins.slice(1).map(function (item) { return txb.object(item.coinObjectId); }));
        }
        var totalAmount = (0, utils_3.calculateTotalAmountToDeposit)(amount, totalFee);
        return txb.splitCoins(coinObject, [txb.pure(totalAmount.toString())])[0];
    };
    /**
     * Return previously splitted Coin Object to owner and merge it to to rebate storage fees
     * @param txb current transanction block
     * @param coinType coin type
     * @param coinObject splitted object
     * @param coins array of objects used in
     */
    SuiStreamClient.prototype.returnSplittedCoinObject = function (txb, coinType, coins, coinObject) {
        var firstCoinObject = coinType === utils_1.SUI_TYPE_ARG ? txb.gas : txb.object(coins[0].coinObjectId);
        txb.mergeCoins(firstCoinObject, [coinObject]);
    };
    return SuiStreamClient;
}(BaseStreamClient_1.BaseStreamClient));
exports.default = SuiStreamClient;
