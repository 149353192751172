"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransferCancelOptions = exports.Contract = exports.Cluster = void 0;
var contractUtils_1 = require("../common/contractUtils");
var utils_1 = require("../common/utils");
var wallet_adapter_base_1 = require("@solana/wallet-adapter-base");
Object.defineProperty(exports, "Cluster", { enumerable: true, get: function () { return wallet_adapter_base_1.WalletAdapterNetwork; } });
var Contract = /** @class */ (function () {
    function Contract(stream) {
        var _a;
        this.magic = stream.magic.toNumber();
        this.version = stream.version.toNumber();
        this.createdAt = stream.createdAt.toNumber();
        this.withdrawnAmount = stream.withdrawnAmount;
        this.canceledAt = stream.canceledAt.toNumber();
        this.end = stream.end.toNumber();
        this.lastWithdrawnAt = stream.lastWithdrawnAt.toNumber();
        this.sender = stream.sender.toBase58();
        this.senderTokens = stream.senderTokens.toBase58();
        this.recipient = stream.recipient.toBase58();
        this.recipientTokens = stream.recipientTokens.toBase58();
        this.mint = stream.mint.toBase58();
        this.escrowTokens = stream.escrowTokens.toBase58();
        this.streamflowTreasury = stream.streamflowTreasury.toBase58();
        this.streamflowTreasuryTokens = stream.streamflowTreasuryTokens.toBase58();
        this.streamflowFeeTotal = stream.streamflowFeeTotal;
        this.streamflowFeeWithdrawn = stream.streamflowFeeWithdrawn;
        this.streamflowFeePercent = stream.streamflowFeePercent.toNumber();
        this.partnerFeeTotal = stream.partnerFeeTotal;
        this.partnerFeeWithdrawn = stream.partnerFeeWithdrawn;
        this.partnerFeePercent = stream.partnerFeePercent.toNumber();
        this.partner = stream.partner.toBase58();
        this.partnerTokens = (_a = stream.partnerTokens) === null || _a === void 0 ? void 0 : _a.toBase58();
        this.start = stream.start.toNumber();
        this.depositedAmount = stream.depositedAmount;
        this.period = stream.period.toNumber();
        this.amountPerPeriod = stream.amountPerPeriod;
        this.cliff = stream.cliff.toNumber();
        this.cliffAmount = stream.cliffAmount;
        this.cancelableBySender = stream.cancelableBySender;
        this.cancelableByRecipient = stream.cancelableByRecipient;
        this.automaticWithdrawal = stream.automaticWithdrawal;
        this.transferableBySender = stream.transferableBySender;
        this.transferableByRecipient = stream.transferableByRecipient;
        this.canTopup = stream.canTopup;
        this.name = stream.name;
        this.withdrawalFrequency = stream.withdrawFrequency.toNumber();
        this.closed = stream.closed;
        this.currentPauseStart = stream.currentPauseStart.toNumber();
        this.pauseCumulative = stream.pauseCumulative;
        this.lastRateChangeTime = stream.lastRateChangeTime.toNumber();
        this.fundsUnlockedAtLastRateChange = stream.fundsUnlockedAtLastRateChange;
        this.type = (0, contractUtils_1.buildStreamType)(this);
    }
    Contract.prototype.unlocked = function (currentTimestamp) {
        return (0, contractUtils_1.calculateUnlockedAmount)(__assign(__assign({}, this), { currentTimestamp: currentTimestamp }));
    };
    Contract.prototype.remaining = function (decimals) {
        return (0, utils_1.getNumberFromBN)(this.depositedAmount.sub(this.withdrawnAmount), decimals);
    };
    return Contract;
}());
exports.Contract = Contract;
var TransferCancelOptions;
(function (TransferCancelOptions) {
    TransferCancelOptions["Recipient"] = "recipient";
    TransferCancelOptions["Sender"] = "sender";
    TransferCancelOptions["Both"] = "both";
    TransferCancelOptions["Neither"] = "neither";
})(TransferCancelOptions || (exports.TransferCancelOptions = TransferCancelOptions = {}));
