"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.SOLANA_ERROR_MAP = exports.SOLANA_ERROR_MATCH_REGEX = exports.PARTNERS_SCHEMA = exports.PARTNER_SCHEMA = exports.AIRDROP_AMOUNT = exports.DEFAULT_STREAMFLOW_FEE = exports.FEES_METADATA_SEED = exports.AIRDROP_TEST_TOKEN = exports.FEE_ORACLE_PUBLIC_KEY = exports.WITHDRAWOR_PUBLIC_KEY = exports.STREAMFLOW_TREASURY_PUBLIC_KEY = exports.PARTNER_ORACLE_PROGRAM_ID = exports.STREAMFLOW_DEVNET_PROGRAM_ID = exports.STREAMFLOW_PROGRAM_ID = exports.PROGRAM_ID = exports.CREATE_PARAMS_PADDING = exports.STREAM_STRUCT_OFFSET_RECIPIENT = exports.STREAM_STRUCT_OFFSET_SENDER = exports.TX_FINALITY_CONFIRMED = void 0;
var web3_js_1 = require("@solana/web3.js");
var types_1 = require("../common/types");
exports.TX_FINALITY_CONFIRMED = "confirmed";
exports.STREAM_STRUCT_OFFSET_SENDER = 49;
exports.STREAM_STRUCT_OFFSET_RECIPIENT = 113;
// Defined: https://github.com/streamflow-finance/protocol/blob/main/programs/protocol/src/state.rs#L25
exports.CREATE_PARAMS_PADDING = 126;
exports.PROGRAM_ID = (_a = {},
    _a[types_1.ICluster.Devnet] = "HqDGZjaVRXJ9MGRQEw7qDc2rAr6iH1n1kAQdCZaCMfMZ",
    _a[types_1.ICluster.Mainnet] = "strmRqUCoQUgGUan5YhzUZa6KqdzwX5L6FpUxfmKg5m",
    _a[types_1.ICluster.Testnet] = "HqDGZjaVRXJ9MGRQEw7qDc2rAr6iH1n1kAQdCZaCMfMZ",
    _a[types_1.ICluster.Local] = "HqDGZjaVRXJ9MGRQEw7qDc2rAr6iH1n1kAQdCZaCMfMZ",
    _a);
exports.STREAMFLOW_PROGRAM_ID = "strmRqUCoQUgGUan5YhzUZa6KqdzwX5L6FpUxfmKg5m";
exports.STREAMFLOW_DEVNET_PROGRAM_ID = "FGjLaVo5zLGdzCxMo9gu9tXr1kzTToKd8C8K7YS5hNM1";
exports.PARTNER_ORACLE_PROGRAM_ID = "pardpVtPjC8nLj1Dwncew62mUzfChdCX1EaoZe8oCAa";
exports.STREAMFLOW_TREASURY_PUBLIC_KEY = new web3_js_1.PublicKey("5SEpbdjFK5FxwTvfsGMXVQTD2v4M2c5tyRTxhdsPkgDw");
exports.WITHDRAWOR_PUBLIC_KEY = new web3_js_1.PublicKey("wdrwhnCv4pzW8beKsbPa4S2UDZrXenjg16KJdKSpb5u");
exports.FEE_ORACLE_PUBLIC_KEY = new web3_js_1.PublicKey("B743wFVk2pCYhV91cn287e1xY7f1vt4gdY48hhNiuQmT");
exports.AIRDROP_TEST_TOKEN = "Gssm3vfi8s65R31SBdmQRq6cKeYojGgup7whkw4VCiQj";
exports.FEES_METADATA_SEED = Buffer.from("strm_fees");
exports.DEFAULT_STREAMFLOW_FEE = 0.99;
exports.AIRDROP_AMOUNT = 1; // 1 SOL is the cap on the testnet
exports.PARTNER_SCHEMA = {
    struct: { pubkey: { array: { type: "u8", len: 32 } }, partner_fee: "f32", strm_fee: "f32" },
};
exports.PARTNERS_SCHEMA = { array: { type: exports.PARTNER_SCHEMA } };
exports.SOLANA_ERROR_MATCH_REGEX = /custom program error: (0x\d{2})/;
exports.SOLANA_ERROR_MAP = {
    0x60: types_1.SolanaContractErrorCode.AccountsNotWritable,
    0x61: types_1.SolanaContractErrorCode.InvalidMetadata,
    0x62: types_1.SolanaContractErrorCode.InvalidMetadataAccount,
    0x63: types_1.SolanaContractErrorCode.MetadataAccountMismatch,
    0x64: types_1.SolanaContractErrorCode.InvalidEscrowAccount,
    0x65: types_1.SolanaContractErrorCode.NotAssociated,
    0x66: types_1.SolanaContractErrorCode.MintMismatch,
    0x67: types_1.SolanaContractErrorCode.TransferNotAllowed,
    0x68: types_1.SolanaContractErrorCode.ContractClosed,
    0x69: types_1.SolanaContractErrorCode.InvalidTreasury,
    0x70: types_1.SolanaContractErrorCode.InvalidTimestamps,
    0x71: types_1.SolanaContractErrorCode.InvalidDepositConfiguration,
    0x72: types_1.SolanaContractErrorCode.AmountIsZero,
    0x73: types_1.SolanaContractErrorCode.AmountMoreThanAvailable,
    0x74: types_1.SolanaContractErrorCode.AmountAvailableIsZero,
    0x80: types_1.SolanaContractErrorCode.ArithmeticError,
    0x81: types_1.SolanaContractErrorCode.InvalidMetadataSize,
    0x82: types_1.SolanaContractErrorCode.UninitializedMetadata,
    0x83: types_1.SolanaContractErrorCode.Unauthorized,
    0x84: types_1.SolanaContractErrorCode.SelfTransfer,
    0x85: types_1.SolanaContractErrorCode.AlreadyPaused,
    0x86: types_1.SolanaContractErrorCode.NotPaused,
    0x87: types_1.SolanaContractErrorCode.MetadataNotRentExempt,
};
