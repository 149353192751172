"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.topupStreamLayout = exports.encodeUpdateStream = exports.withdrawStreamLayout = exports.createUncheckedStreamLayout = exports.createStreamLayout = exports.partnerLayout = exports.streamLayout = void 0;
var BufferLayout = __importStar(require("@solana/buffer-layout"));
var constants_1 = require("./constants");
/**
 * Stream layout
 */
exports.streamLayout = BufferLayout.struct([
    BufferLayout.blob(8, "magic"),
    BufferLayout.blob(1, "version"),
    BufferLayout.blob(8, "created_at"),
    BufferLayout.blob(8, "withdrawn_amount"),
    BufferLayout.blob(8, "canceled_at"),
    BufferLayout.blob(8, "end_time"),
    BufferLayout.blob(8, "last_withdrawn_at"),
    BufferLayout.blob(32, "sender"),
    BufferLayout.blob(32, "sender_tokens"),
    BufferLayout.blob(32, "recipient"),
    BufferLayout.blob(32, "recipient_tokens"),
    BufferLayout.blob(32, "mint"),
    BufferLayout.blob(32, "escrow_tokens"),
    BufferLayout.blob(32, "streamflow_treasury"),
    BufferLayout.blob(32, "streamflow_treasury_tokens"),
    BufferLayout.blob(8, "streamflow_fee_total"),
    BufferLayout.blob(8, "streamflow_fee_withdrawn"),
    BufferLayout.f32("streamflow_fee_percent"),
    BufferLayout.blob(32, "partner"),
    BufferLayout.blob(32, "partner_tokens"),
    BufferLayout.blob(8, "partner_fee_total"),
    BufferLayout.blob(8, "partner_fee_withdrawn"),
    BufferLayout.f32("partner_fee_percent"),
    BufferLayout.blob(8, "start_time"),
    BufferLayout.blob(8, "net_amount_deposited"),
    BufferLayout.blob(8, "period"),
    BufferLayout.blob(8, "amount_per_period"),
    BufferLayout.blob(8, "cliff"),
    BufferLayout.blob(8, "cliff_amount"),
    BufferLayout.u8("cancelable_by_sender"),
    BufferLayout.u8("cancelable_by_recipient"),
    BufferLayout.u8("automatic_withdrawal"),
    BufferLayout.u8("transferable_by_sender"),
    BufferLayout.u8("transferable_by_recipient"),
    BufferLayout.u8("can_topup"),
    BufferLayout.blob(64, "stream_name"),
    BufferLayout.blob(8, "withdraw_frequency"),
    // Unused, kept for backward compatibility™
    BufferLayout.blob(4, "ghost"),
    BufferLayout.u8("pausable"),
    BufferLayout.u8("can_update_rate"),
    BufferLayout.blob(4, "create_stream_params_padding_length"),
    BufferLayout.seq(BufferLayout.u8(), constants_1.CREATE_PARAMS_PADDING, "create_params_padding"),
    BufferLayout.u8("closed"),
    BufferLayout.blob(8, "current_pause_start"),
    BufferLayout.blob(8, "pause_cumulative"),
    BufferLayout.blob(8, "last_rate_change_time"),
    BufferLayout.blob(8, "funds_unlocked_at_last_rate_change"),
]);
exports.partnerLayout = BufferLayout.struct([
    BufferLayout.blob(32, "pubkey"),
    BufferLayout.f32("partner_fee"),
    BufferLayout.f32("strm_fee"),
]);
/**
 * Create stream instruction layout
 */
exports.createStreamLayout = BufferLayout.struct([
    BufferLayout.blob(8, "start_time"),
    BufferLayout.blob(8, "net_amount_deposited"),
    BufferLayout.blob(8, "period"),
    BufferLayout.blob(8, "amount_per_period"),
    BufferLayout.blob(8, "cliff"),
    BufferLayout.blob(8, "cliff_amount"),
    BufferLayout.u8("cancelable_by_sender"),
    BufferLayout.u8("cancelable_by_recipient"),
    BufferLayout.u8("automatic_withdrawal"),
    BufferLayout.u8("transferable_by_sender"),
    BufferLayout.u8("transferable_by_recipient"),
    BufferLayout.u8("can_topup"),
    BufferLayout.blob(64, "stream_name"),
    BufferLayout.blob(8, "withdraw_frequency"),
]);
/**
 * Create unchecked stream instruction layout
 */
exports.createUncheckedStreamLayout = BufferLayout.struct([
    BufferLayout.blob(8, "start_time"),
    BufferLayout.blob(8, "net_amount_deposited"),
    BufferLayout.blob(8, "period"),
    BufferLayout.blob(8, "amount_per_period"),
    BufferLayout.blob(8, "cliff"),
    BufferLayout.blob(8, "cliff_amount"),
    BufferLayout.u8("cancelable_by_sender"),
    BufferLayout.u8("cancelable_by_recipient"),
    BufferLayout.u8("automatic_withdrawal"),
    BufferLayout.u8("transferable_by_sender"),
    BufferLayout.u8("transferable_by_recipient"),
    BufferLayout.u8("can_topup"),
    BufferLayout.blob(64, "stream_name"),
    BufferLayout.blob(8, "withdraw_frequency"),
    BufferLayout.blob(32, "recipient"),
    BufferLayout.blob(32, "partner"),
    BufferLayout.u8("pausable"),
    BufferLayout.u8("can_update_rate"),
]);
/**
 * Withdraw stream instruction layout
 */
exports.withdrawStreamLayout = BufferLayout.struct([BufferLayout.blob(8, "amount")]);
/**
 * Encode stream instruction layout
 */
var encodeUpdateStream = function (values, data) {
    var _a, _b, _c;
    var structs = [];
    if (values.enable_automatic_withdrawal) {
        structs.push(BufferLayout.u8("enable_automatic_withdrawal_exists"));
        structs.push(BufferLayout.u8("enable_automatic_withdrawal"));
    }
    else {
        structs.push(BufferLayout.u8("enable_automatic_withdrawal_exists"));
    }
    if (values.withdraw_frequency) {
        structs.push(BufferLayout.u8("withdraw_frequency_exists"));
        structs.push(BufferLayout.blob(8, "withdraw_frequency"));
    }
    else {
        structs.push(BufferLayout.u8("withdraw_frequency_exists"));
    }
    if (values.amount_per_period) {
        structs.push(BufferLayout.u8("amount_per_period_exists"));
        structs.push(BufferLayout.blob(8, "amount_per_period"));
    }
    else {
        structs.push(BufferLayout.u8("amount_per_period_exists"));
    }
    return BufferLayout.struct(structs).encode({
        enable_automatic_withdrawal_exists: values.enable_automatic_withdrawal ? 1 : 0,
        enable_automatic_withdrawal: (_a = values.enable_automatic_withdrawal) !== null && _a !== void 0 ? _a : 0,
        withdraw_frequency_exists: values.withdraw_frequency ? 1 : 0,
        withdraw_frequency: (_b = values.withdraw_frequency) !== null && _b !== void 0 ? _b : 0,
        amount_per_period_exists: values.amount_per_period ? 1 : 0,
        amount_per_period: (_c = values.amount_per_period) !== null && _c !== void 0 ? _c : 0,
    }, data);
};
exports.encodeUpdateStream = encodeUpdateStream;
/**
 * Topup stream instruction layout
 */
exports.topupStreamLayout = BufferLayout.struct([
    BufferLayout.blob(8, "amount"),
]);
