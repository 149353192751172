"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.APTOS_MODULE_ERROR_MAP = exports.APTOS_ERROR_MATCH_REGEX = exports.APTOS_PROGRAM_IDS = void 0;
var types_1 = require("../common/types");
exports.APTOS_PROGRAM_IDS = (_a = {},
    _a[types_1.ICluster.Mainnet] = "0x9009d93d52576bf9ac6dc6cf10b870610bcb316342fef6eff80662fbbfce51b0",
    // TODO: Add a correct programID for Test net
    _a[types_1.ICluster.Devnet] = "0xc6737de143d91b2f99a7e490d4f8348fdfa3bdd1eb8737a27d0455f8a3625688",
    _a[types_1.ICluster.Testnet] = "0xc6737de143d91b2f99a7e490d4f8348fdfa3bdd1eb8737a27d0455f8a3625688",
    _a[types_1.ICluster.Local] = "0x9009d93d52576bf9ac6dc6cf10b870610bcb316342fef6eff80662fbbfce51b0",
    _a);
exports.APTOS_ERROR_MATCH_REGEX = /Move abort in [a-z0-9]+::([^:]+): ([A-Z_]+)/;
exports.APTOS_MODULE_ERROR_MAP = {
    protocol: {
        1: types_1.ContractErrorCode.ECONTRACT_NOT_INIT,
        2: types_1.ContractErrorCode.EBAD_AMOUNT,
        3: types_1.ContractErrorCode.ENO_PERMISSIONS,
        4: types_1.ContractErrorCode.EBADINPUT,
        5: types_1.ContractErrorCode.ECLOSED,
        6: types_1.ContractErrorCode.EBAD_INPUT_AMOUNT_PER_PERIOD,
        8: types_1.ContractErrorCode.EBAD_INPUT_UPDATE_RATE,
        9: types_1.ContractErrorCode.EBAD_INPUT_CLIFF_AMOUNT,
        10: types_1.ContractErrorCode.EBAD_INPUT_PERIOD,
        11: types_1.ContractErrorCode.EBAD_INPUT_START,
        12: types_1.ContractErrorCode.ENO_RECIPIENT_COIN_ADDRESS,
    },
};
