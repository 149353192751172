"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractSuiErrorInfo = void 0;
var constants_1 = require("./constants");
function extractSuiErrorInfo(errorText) {
    var error = { text: errorText };
    var match = constants_1.SUI_ERROR_MATCH_REGEX.exec(errorText);
    if (!match) {
        return error;
    }
    var moduleName = match[2];
    var errorCode = Number(match[4]);
    var errorName = constants_1.SUI_MODULE_ERROR_MAP[moduleName]
        ? constants_1.SUI_MODULE_ERROR_MAP[moduleName][errorCode]
        : undefined;
    var index = Number(match[5]);
    if (index) {
        error.index = index;
    }
    if (errorName) {
        error.parsed = {
            module: moduleName,
            code: errorCode,
            name: errorName,
        };
    }
    return error;
}
exports.extractSuiErrorInfo = extractSuiErrorInfo;
