"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractEvmErrorCode = void 0;
var constants_1 = require("./constants");
function extractEvmErrorCode(errorText) {
    var match = constants_1.EVM_ERROR_MATCH_REGEX.exec(errorText);
    if (!match) {
        return null;
    }
    return match[1];
}
exports.extractEvmErrorCode = extractEvmErrorCode;
