"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseStreamClient_1 = require("./BaseStreamClient");
var types_1 = require("./types");
var utils_1 = require("./utils");
var aptos_1 = require("../aptos");
var evm_1 = require("../evm");
var solana_1 = require("../solana");
var sui_1 = require("../sui");
var constants_1 = require("./constants");
/** Generic Stream Client implementation that wrap Chain Client methods and enriches error messages if possible
 * @property {StreamClientType} - Chain Client implementation
 * @property {chain} - Chain
 */
var GenericStreamClient = /** @class */ (function (_super) {
    __extends(GenericStreamClient, _super);
    function GenericStreamClient(options) {
        var _this = _super.call(this) || this;
        _this.chain = options.chain;
        switch (options.chain) {
            case types_1.IChain.Solana:
                _this.nativeStreamClient = new solana_1.SolanaStreamClient(options.clusterUrl, options.cluster, options.commitment, options.programId);
                break;
            case types_1.IChain.Aptos:
                _this.nativeStreamClient = new aptos_1.AptosStreamClient(options.clusterUrl, options.cluster, options.maxGas, options.programId);
                break;
            case types_1.IChain.BNB:
            case types_1.IChain.Ethereum:
            case types_1.IChain.Polygon:
                _this.nativeStreamClient = new evm_1.EvmStreamClient(options.clusterUrl, options.chain, options.signer, options.cluster, options.programId);
                break;
            case types_1.IChain.Sui:
                _this.nativeStreamClient = new sui_1.SuiStreamClient(options.clusterUrl, options.cluster, options.ids);
        }
        return _this;
    }
    /**
     * Creates a new stream/vesting contract.
     */
    GenericStreamClient.prototype.create = function (streamData, chainSpecificParams) {
        var _this = this;
        return (0, utils_1.handleContractError)(function () { return _this.nativeStreamClient.create(streamData, chainSpecificParams); }, this.nativeStreamClient.extractErrorCode);
    };
    /**
     * Creates multiple stream/vesting contracts.
     */
    GenericStreamClient.prototype.createMultiple = function (multipleStreamData, chainSpecificParams) {
        var _this = this;
        return (0, utils_1.handleContractError)(function () { return _this.nativeStreamClient.createMultiple(multipleStreamData, chainSpecificParams); }, this.nativeStreamClient.extractErrorCode);
    };
    /**
     * Attempts withdrawing from the specified stream.
     */
    GenericStreamClient.prototype.withdraw = function (_a, chainSpecificParams) {
        var _this = this;
        var id = _a.id, _b = _a.amount, amount = _b === void 0 ? constants_1.WITHDRAW_AVAILABLE_AMOUNT : _b;
        return (0, utils_1.handleContractError)(function () { return _this.nativeStreamClient.withdraw({ id: id, amount: amount }, chainSpecificParams); }, this.nativeStreamClient.extractErrorCode);
    };
    /**
     * Attempts canceling the specified stream.
     */
    GenericStreamClient.prototype.cancel = function (cancelData, chainSpecificParams) {
        var _this = this;
        return (0, utils_1.handleContractError)(function () { return _this.nativeStreamClient.cancel(cancelData, chainSpecificParams); }, this.nativeStreamClient.extractErrorCode);
    };
    /**
     * Attempts changing the stream/vesting contract's recipient (effectively transferring the stream/vesting contract).
     */
    GenericStreamClient.prototype.transfer = function (transferData, chainSpecificParams) {
        var _this = this;
        return (0, utils_1.handleContractError)(function () { return _this.nativeStreamClient.transfer(transferData, chainSpecificParams); }, this.nativeStreamClient.extractErrorCode);
    };
    /**
     * Tops up stream account with specified amount.
     */
    GenericStreamClient.prototype.topup = function (topupData, chainSpecificParams) {
        var _this = this;
        return (0, utils_1.handleContractError)(function () { return _this.nativeStreamClient.topup(topupData, chainSpecificParams); }, this.nativeStreamClient.extractErrorCode);
    };
    /**
     * Fetch stream data by its id (address).
     */
    GenericStreamClient.prototype.getOne = function (getOneData) {
        return this.nativeStreamClient.getOne(getOneData);
    };
    /**
     * Fetch streams by sender or recipient address.
     */
    GenericStreamClient.prototype.get = function (getAllData) {
        return this.nativeStreamClient.get(getAllData);
    };
    /**
     * Attempts updating the stream auto withdrawal params and amount per period
     */
    GenericStreamClient.prototype.update = function (updateData, chainSpecificParams) {
        var _this = this;
        return (0, utils_1.handleContractError)(function () { return _this.nativeStreamClient.update(updateData, chainSpecificParams); }, this.nativeStreamClient.extractErrorCode);
    };
    /**
     * Returns streamflow and partner fees for the specific wallet in %
     */
    GenericStreamClient.prototype.getFees = function (getFeesData) {
        return this.nativeStreamClient.getFees(getFeesData);
    };
    /**
     * Returns default Streamflow Fee in %
     */
    GenericStreamClient.prototype.getDefaultStreamflowFee = function () {
        return this.nativeStreamClient.getDefaultStreamflowFee();
    };
    return GenericStreamClient;
}(BaseStreamClient_1.BaseStreamClient));
exports.default = GenericStreamClient;
