"use strict";
var _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.EVM_ERROR_MAP = exports.EVM_ERROR_MATCH_REGEX = exports.POLYGON_PROGRAM_IDS = exports.BNB_PROGRAM_IDS = exports.ETHEREUM_PROGRAM_IDS = void 0;
var types_1 = require("../common/types");
exports.ETHEREUM_PROGRAM_IDS = (_a = {},
    _a[types_1.ICluster.Mainnet] = "0x94d4646Bd307Bf91CB1893BC64d976BF9E60D9B2",
    _a[types_1.ICluster.Devnet] = "0x5Db7a43D20De64E3a3BC765334a477026FD13E7d",
    _a[types_1.ICluster.Testnet] = "0x5Db7a43D20De64E3a3BC765334a477026FD13E7d",
    _a[types_1.ICluster.Local] = "0x5Db7a43D20De64E3a3BC765334a477026FD13E7d",
    _a);
exports.BNB_PROGRAM_IDS = (_b = {},
    _b[types_1.ICluster.Mainnet] = "0x94d4646Bd307Bf91CB1893BC64d976BF9E60D9B2",
    _b[types_1.ICluster.Devnet] = "0x5Db7a43D20De64E3a3BC765334a477026FD13E7d",
    _b[types_1.ICluster.Testnet] = "0x5Db7a43D20De64E3a3BC765334a477026FD13E7d",
    _b[types_1.ICluster.Local] = "0x5Db7a43D20De64E3a3BC765334a477026FD13E7d",
    _b);
exports.POLYGON_PROGRAM_IDS = (_c = {},
    _c[types_1.ICluster.Mainnet] = "0x94d4646Bd307Bf91CB1893BC64d976BF9E60D9B2",
    _c[types_1.ICluster.Devnet] = "0x5Db7a43D20De64E3a3BC765334a477026FD13E7d",
    _c[types_1.ICluster.Testnet] = "0x5Db7a43D20De64E3a3BC765334a477026FD13E7d",
    _c[types_1.ICluster.Local] = "0x5Db7a43D20De64E3a3BC765334a477026FD13E7d",
    _c);
exports.EVM_ERROR_MATCH_REGEX = /execution reverted: ([A-Z_]+)/;
exports.EVM_ERROR_MAP = {
    ECONTRACT_NOT_INIT: types_1.ContractErrorCode.ECONTRACT_NOT_INIT,
    EBAD_AMOUNT: types_1.ContractErrorCode.EBAD_AMOUNT,
    ENO_PERMISSIONS: types_1.ContractErrorCode.ENO_PERMISSIONS,
    EBADINPUT: types_1.ContractErrorCode.EBADINPUT,
    ECLOSED: types_1.ContractErrorCode.ECLOSED,
    EBAD_INPUT_AMOUNT_PER_PERIOD: types_1.ContractErrorCode.EBAD_INPUT_AMOUNT_PER_PERIOD,
    EBAD_INPUT_PAUSABLE: types_1.ContractErrorCode.EBAD_INPUT_PAUSABLE,
    EBAD_INPUT_UPDATE_RATE: types_1.ContractErrorCode.EBAD_INPUT_UPDATE_RATE,
    EBAD_INPUT_CLIFF_AMOUNT: types_1.ContractErrorCode.EBAD_INPUT_CLIFF_AMOUNT,
    EBAD_INPUT_PERIOD: types_1.ContractErrorCode.EBAD_INPUT_PERIOD,
    EBAD_INPUT_START: types_1.ContractErrorCode.EBAD_INPUT_START,
    EBAD_INSUFFICIENT_TOKEN_ALLOWANCE: types_1.ContractErrorCode.EBAD_INSUFFICIENT_TOKEN_ALLOWANCE,
    EBAD_INSUFFICIENT_WITHDRAWAL_FEES: types_1.ContractErrorCode.EBAD_INSUFFICIENT_WITHDRAWAL_FEES,
};
