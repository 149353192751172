"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeAptosAddress = exports.extractAptosErrorCode = void 0;
var constants_1 = require("./constants");
function extractAptosErrorCode(errorText) {
    var match = constants_1.APTOS_ERROR_MATCH_REGEX.exec(errorText);
    if (!match) {
        return null;
    }
    return match[2];
}
exports.extractAptosErrorCode = extractAptosErrorCode;
function isAddressSpecial(address) {
    if (address.length === 3) {
        return true;
    }
    return false;
}
/**
 * Aptos has inconsistencies in how it returns addresses.
 * This method normalizes them to be 64+2(0x...) characters long, or leaves it as SPECIAL ADDRESS (0x0 - 0xf inclusive)
 * Per this: https://github.com/aptos-labs/aptos-ts-sdk/blob/main/src/core/accountAddress.ts#L115
 * */
function normalizeAptosAddress(address) {
    if (isAddressSpecial(address)) {
        return address;
    }
    var length = address.length;
    if (length === 66 || length < 3) {
        return address;
    }
    var missingZeros = 66 - length;
    return address.slice(0, 2) + "0".repeat(missingZeros) + address.slice(2);
}
exports.normalizeAptosAddress = normalizeAptosAddress;
