"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.prepareWrappedAccount = exports.topupStreamInstruction = exports.transferStreamInstruction = exports.cancelStreamInstruction = exports.updateStreamInstruction = exports.withdrawStreamInstruction = exports.createUncheckedStreamInstruction = exports.createStreamInstruction = void 0;
var buffer_1 = require("buffer");
var web3_js_1 = require("@solana/web3.js");
var js_sha256_1 = require("js-sha256");
var spl_token_1 = require("@solana/spl-token");
var Layout = __importStar(require("./layout"));
var createStreamInstruction = function (data, programId, accounts) {
    var keys = [
        { pubkey: accounts.sender, isSigner: true, isWritable: true },
        { pubkey: accounts.senderTokens, isSigner: false, isWritable: true },
        { pubkey: accounts.recipient, isSigner: false, isWritable: true },
        { pubkey: accounts.metadata, isSigner: true, isWritable: true },
        { pubkey: accounts.escrowTokens, isSigner: false, isWritable: true },
        { pubkey: accounts.recipientTokens, isSigner: false, isWritable: true },
        {
            pubkey: accounts.streamflowTreasury,
            isSigner: false,
            isWritable: true,
        },
        {
            pubkey: accounts.streamflowTreasuryTokens,
            isSigner: false,
            isWritable: true,
        },
        { pubkey: accounts.withdrawor, isSigner: false, isWritable: true },
        { pubkey: accounts.partner, isSigner: false, isWritable: true },
        { pubkey: accounts.partnerTokens, isSigner: false, isWritable: true },
        { pubkey: accounts.mint, isSigner: false, isWritable: false },
        { pubkey: accounts.feeOracle, isSigner: false, isWritable: false },
        { pubkey: accounts.rent, isSigner: false, isWritable: false },
        { pubkey: accounts.timelockProgram, isSigner: false, isWritable: false },
        { pubkey: accounts.tokenProgram, isSigner: false, isWritable: false },
        {
            pubkey: accounts.associatedTokenProgram,
            isSigner: false,
            isWritable: false,
        },
        { pubkey: accounts.systemProgram, isSigner: false, isWritable: false },
    ];
    var bufferData = buffer_1.Buffer.alloc(Layout.createStreamLayout.span);
    var encodedUIntArray = new TextEncoder().encode(data.name);
    var streamNameBuffer = buffer_1.Buffer.alloc(64).fill(encodedUIntArray, 0, encodedUIntArray.byteLength);
    var decodedData = {
        start_time: data.start.toArrayLike(buffer_1.Buffer, "le", 8),
        net_amount_deposited: data.depositedAmount.toArrayLike(buffer_1.Buffer, "le", 8),
        period: data.period.toArrayLike(buffer_1.Buffer, "le", 8),
        amount_per_period: data.amountPerPeriod.toArrayLike(buffer_1.Buffer, "le", 8),
        cliff: data.cliff.toArrayLike(buffer_1.Buffer, "le", 8),
        cliff_amount: data.cliffAmount.toArrayLike(buffer_1.Buffer, "le", 8),
        cancelable_by_sender: Number(data.cancelableBySender),
        cancelable_by_recipient: Number(data.cancelableByRecipient),
        automatic_withdrawal: Number(data.automaticWithdrawal),
        transferable_by_sender: Number(data.transferableBySender),
        transferable_by_recipient: Number(data.transferableByRecipient),
        can_topup: Number(data.canTopup),
        stream_name: streamNameBuffer,
        withdraw_frequency: data.withdrawFrequency.toArrayLike(buffer_1.Buffer, "le", 8),
    };
    var encodeLength = Layout.createStreamLayout.encode(decodedData, bufferData);
    bufferData = bufferData.slice(0, encodeLength);
    bufferData = buffer_1.Buffer.concat([
        buffer_1.Buffer.from(js_sha256_1.sha256.digest("global:create")).slice(0, 8),
        bufferData,
        buffer_1.Buffer.alloc(10),
    ]);
    return new web3_js_1.TransactionInstruction({
        keys: keys,
        programId: programId,
        data: bufferData,
    });
};
exports.createStreamInstruction = createStreamInstruction;
var createUncheckedStreamInstruction = function (data, programId, accounts) {
    var keys = [
        { pubkey: accounts.sender, isSigner: true, isWritable: true },
        { pubkey: accounts.senderTokens, isSigner: false, isWritable: true },
        { pubkey: accounts.metadata, isSigner: false, isWritable: true },
        { pubkey: accounts.escrowTokens, isSigner: false, isWritable: true },
        { pubkey: accounts.withdrawor, isSigner: false, isWritable: true },
        { pubkey: accounts.mint, isSigner: false, isWritable: false },
        { pubkey: accounts.feeOracle, isSigner: false, isWritable: false },
        { pubkey: accounts.rent, isSigner: false, isWritable: false },
        { pubkey: accounts.timelockProgram, isSigner: false, isWritable: false },
        { pubkey: accounts.tokenProgram, isSigner: false, isWritable: false },
        { pubkey: accounts.systemProgram, isSigner: false, isWritable: false },
    ];
    if (accounts.payer) {
        keys.unshift({
            pubkey: accounts.payer,
            isSigner: true,
            isWritable: true,
        });
    }
    var bufferData = buffer_1.Buffer.alloc(Layout.createUncheckedStreamLayout.span);
    var encodedUIntArray = new TextEncoder().encode(data.name);
    var streamNameBuffer = buffer_1.Buffer.alloc(64).fill(encodedUIntArray, 0, encodedUIntArray.byteLength);
    var decodedData = {
        start_time: data.start.toArrayLike(buffer_1.Buffer, "le", 8),
        net_amount_deposited: data.depositedAmount.toArrayLike(buffer_1.Buffer, "le", 8),
        period: data.period.toArrayLike(buffer_1.Buffer, "le", 8),
        amount_per_period: data.amountPerPeriod.toArrayLike(buffer_1.Buffer, "le", 8),
        cliff: data.cliff.toArrayLike(buffer_1.Buffer, "le", 8),
        cliff_amount: data.cliffAmount.toArrayLike(buffer_1.Buffer, "le", 8),
        cancelable_by_sender: Number(data.cancelableBySender),
        cancelable_by_recipient: Number(data.cancelableByRecipient),
        automatic_withdrawal: Number(data.automaticWithdrawal),
        transferable_by_sender: Number(data.transferableBySender),
        transferable_by_recipient: Number(data.transferableByRecipient),
        can_topup: Number(data.canTopup),
        stream_name: streamNameBuffer,
        withdraw_frequency: data.withdrawFrequency.toArrayLike(buffer_1.Buffer, "le", 8),
        recipient: data.recipient.toBuffer(),
        partner: data.partner.toBuffer(),
        pausable: 1,
        can_update_rate: 1,
    };
    var encodeLength = Layout.createUncheckedStreamLayout.encode(decodedData, bufferData);
    bufferData = bufferData.slice(0, encodeLength);
    var digest = accounts.payer
        ? js_sha256_1.sha256.digest("global:create_unchecked_with_payer")
        : js_sha256_1.sha256.digest("global:create_unchecked");
    bufferData = buffer_1.Buffer.concat([buffer_1.Buffer.from(digest).slice(0, 8), bufferData, buffer_1.Buffer.alloc(10)]);
    return new web3_js_1.TransactionInstruction({
        keys: keys,
        programId: programId,
        data: bufferData,
    });
};
exports.createUncheckedStreamInstruction = createUncheckedStreamInstruction;
var withdrawStreamInstruction = function (amount, programId, _a) {
    var authority = _a.authority, recipient = _a.recipient, recipientTokens = _a.recipientTokens, metadata = _a.metadata, escrowTokens = _a.escrowTokens, streamflowTreasury = _a.streamflowTreasury, streamflowTreasuryTokens = _a.streamflowTreasuryTokens, partner = _a.partner, partnerTokens = _a.partnerTokens, mint = _a.mint, tokenProgram = _a.tokenProgram;
    var keys = [
        { pubkey: authority, isSigner: true, isWritable: true },
        { pubkey: recipient, isSigner: false, isWritable: true },
        { pubkey: recipientTokens, isSigner: false, isWritable: true },
        { pubkey: metadata, isSigner: false, isWritable: true },
        { pubkey: escrowTokens, isSigner: false, isWritable: true },
        {
            pubkey: streamflowTreasury,
            isSigner: false,
            isWritable: true,
        },
        { pubkey: streamflowTreasuryTokens, isSigner: false, isWritable: true },
        { pubkey: partner, isSigner: false, isWritable: true },
        { pubkey: partnerTokens, isSigner: false, isWritable: true },
        { pubkey: mint, isSigner: false, isWritable: false },
        { pubkey: tokenProgram, isSigner: false, isWritable: false },
    ];
    var data = buffer_1.Buffer.alloc(Layout.withdrawStreamLayout.span);
    var decodedData = { amount: amount.toArrayLike(buffer_1.Buffer, "le", 8) };
    var encodeLength = Layout.withdrawStreamLayout.encode(decodedData, data);
    data = data.slice(0, encodeLength);
    data = buffer_1.Buffer.concat([
        buffer_1.Buffer.from(js_sha256_1.sha256.digest("global:withdraw")).slice(0, 8),
        data,
        buffer_1.Buffer.alloc(10),
    ]);
    return new web3_js_1.TransactionInstruction({
        keys: keys,
        programId: programId,
        data: data,
    });
};
exports.withdrawStreamInstruction = withdrawStreamInstruction;
var updateStreamInstruction = function (params, programId, _a) {
    var authority = _a.authority, metadata = _a.metadata, withdrawor = _a.withdrawor, systemProgram = _a.systemProgram;
    var keys = [
        { pubkey: authority, isSigner: true, isWritable: true },
        { pubkey: metadata, isSigner: false, isWritable: true },
        { pubkey: withdrawor, isSigner: false, isWritable: true },
        { pubkey: systemProgram, isSigner: false, isWritable: false },
    ];
    var data = buffer_1.Buffer.alloc(100);
    var decodedData = {
        enable_automatic_withdrawal: Number(params.enableAutomaticWithdrawal),
        withdraw_frequency: params.withdrawFrequency
            ? params.withdrawFrequency.toArrayLike(buffer_1.Buffer, "le", 8)
            : undefined,
        amount_per_period: params.amountPerPeriod
            ? params.amountPerPeriod.toArrayLike(buffer_1.Buffer, "le", 8)
            : undefined,
    };
    var encodeLength = Layout.encodeUpdateStream(decodedData, data);
    data = data.slice(0, encodeLength);
    data = buffer_1.Buffer.concat([
        buffer_1.Buffer.from(js_sha256_1.sha256.digest("global:update")).slice(0, 8),
        data,
        buffer_1.Buffer.alloc(20),
    ]);
    return new web3_js_1.TransactionInstruction({
        keys: keys,
        programId: programId,
        data: data,
    });
};
exports.updateStreamInstruction = updateStreamInstruction;
var cancelStreamInstruction = function (programId, _a) {
    var authority = _a.authority, sender = _a.sender, senderTokens = _a.senderTokens, recipient = _a.recipient, recipientTokens = _a.recipientTokens, metadata = _a.metadata, escrowTokens = _a.escrowTokens, streamflowTreasury = _a.streamflowTreasury, streamflowTreasuryTokens = _a.streamflowTreasuryTokens, partner = _a.partner, partnerTokens = _a.partnerTokens, mint = _a.mint, tokenProgram = _a.tokenProgram;
    var keys = [
        { pubkey: authority, isSigner: true, isWritable: false },
        { pubkey: sender, isSigner: false, isWritable: true },
        { pubkey: senderTokens, isSigner: false, isWritable: true },
        { pubkey: recipient, isSigner: false, isWritable: true },
        { pubkey: recipientTokens, isSigner: false, isWritable: true },
        { pubkey: metadata, isSigner: false, isWritable: true },
        { pubkey: escrowTokens, isSigner: false, isWritable: true },
        {
            pubkey: streamflowTreasury,
            isSigner: false,
            isWritable: true,
        },
        { pubkey: streamflowTreasuryTokens, isSigner: false, isWritable: true },
        { pubkey: partner, isSigner: false, isWritable: true },
        { pubkey: partnerTokens, isSigner: false, isWritable: true },
        { pubkey: mint, isSigner: false, isWritable: false },
        { pubkey: tokenProgram, isSigner: false, isWritable: false },
    ];
    var data = buffer_1.Buffer.concat([
        buffer_1.Buffer.from(js_sha256_1.sha256.digest("global:cancel")).slice(0, 8),
        buffer_1.Buffer.alloc(10),
    ]);
    return new web3_js_1.TransactionInstruction({
        keys: keys,
        programId: programId,
        data: data,
    });
};
exports.cancelStreamInstruction = cancelStreamInstruction;
var transferStreamInstruction = function (programId, _a) {
    var authority = _a.authority, newRecipient = _a.newRecipient, newRecipientTokens = _a.newRecipientTokens, metadata = _a.metadata, mint = _a.mint, rent = _a.rent, tokenProgram = _a.tokenProgram, associatedTokenProgram = _a.associatedTokenProgram, systemProgram = _a.systemProgram;
    var keys = [
        { pubkey: authority, isSigner: true, isWritable: true },
        { pubkey: newRecipient, isSigner: false, isWritable: true },
        { pubkey: newRecipientTokens, isSigner: false, isWritable: true },
        { pubkey: metadata, isSigner: false, isWritable: true },
        { pubkey: mint, isSigner: false, isWritable: false },
        { pubkey: rent, isSigner: false, isWritable: false },
        { pubkey: tokenProgram, isSigner: false, isWritable: false },
        { pubkey: associatedTokenProgram, isSigner: false, isWritable: false },
        { pubkey: systemProgram, isSigner: false, isWritable: false },
    ];
    var data = buffer_1.Buffer.concat([
        buffer_1.Buffer.from(js_sha256_1.sha256.digest("global:transfer_recipient")).slice(0, 8),
        buffer_1.Buffer.alloc(10),
    ]);
    return new web3_js_1.TransactionInstruction({
        keys: keys,
        programId: programId,
        data: data,
    });
};
exports.transferStreamInstruction = transferStreamInstruction;
var topupStreamInstruction = function (amount, programId, _a) {
    var sender = _a.sender, senderTokens = _a.senderTokens, metadata = _a.metadata, escrowTokens = _a.escrowTokens, streamflowTreasury = _a.streamflowTreasury, streamflowTreasuryTokens = _a.streamflowTreasuryTokens, partner = _a.partner, partnerTokens = _a.partnerTokens, mint = _a.mint, tokenProgram = _a.tokenProgram, withdrawor = _a.withdrawor, systemProgram = _a.systemProgram;
    var keys = [
        { pubkey: sender, isSigner: true, isWritable: true },
        { pubkey: senderTokens, isSigner: false, isWritable: true },
        { pubkey: metadata, isSigner: false, isWritable: true },
        { pubkey: escrowTokens, isSigner: false, isWritable: true },
        {
            pubkey: streamflowTreasury,
            isSigner: false,
            isWritable: true,
        },
        { pubkey: streamflowTreasuryTokens, isSigner: false, isWritable: true },
        { pubkey: withdrawor, isSigner: false, isWritable: true },
        { pubkey: partner, isSigner: false, isWritable: true },
        { pubkey: partnerTokens, isSigner: false, isWritable: true },
        { pubkey: mint, isSigner: false, isWritable: false },
        { pubkey: tokenProgram, isSigner: false, isWritable: false },
        { pubkey: systemProgram, isSigner: false, isWritable: false },
    ];
    var data = buffer_1.Buffer.alloc(Layout.topupStreamLayout.span);
    var decodedData = { amount: amount.toArrayLike(buffer_1.Buffer, "le", 8) };
    var encodeLength = Layout.topupStreamLayout.encode(decodedData, data);
    data = data.slice(0, encodeLength);
    data = buffer_1.Buffer.concat([
        buffer_1.Buffer.from(js_sha256_1.sha256.digest("global:topup")).slice(0, 8),
        data,
        buffer_1.Buffer.alloc(10),
    ]);
    return new web3_js_1.TransactionInstruction({
        keys: keys,
        programId: programId,
        data: data,
    });
};
exports.topupStreamInstruction = topupStreamInstruction;
var prepareWrappedAccount = function (connection, senderAddress, amount) { return __awaiter(void 0, void 0, void 0, function () {
    var tokenAccount, accInfo, instructions;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, (0, spl_token_1.getAssociatedTokenAddress)(spl_token_1.NATIVE_MINT, senderAddress, true)];
            case 1:
                tokenAccount = _c.sent();
                return [4 /*yield*/, connection.getParsedAccountInfo(tokenAccount)];
            case 2:
                accInfo = _c.sent();
                instructions = ((_b = (_a = accInfo.value) === null || _a === void 0 ? void 0 : _a.lamports) !== null && _b !== void 0 ? _b : 0) > 0
                    ? []
                    : [
                        (0, spl_token_1.createAssociatedTokenAccountInstruction)(senderAddress, tokenAccount, senderAddress, spl_token_1.NATIVE_MINT),
                    ];
                return [2 /*return*/, __spreadArray(__spreadArray([], __read(instructions), false), [
                        web3_js_1.SystemProgram.transfer({
                            fromPubkey: senderAddress,
                            toPubkey: tokenAccount,
                            lamports: amount.toNumber(),
                        }),
                        (0, spl_token_1.createSyncNativeInstruction)(tokenAccount),
                    ], false)];
        }
    });
}); };
exports.prepareWrappedAccount = prepareWrappedAccount;
