"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractError = exports.SolanaContractErrorCode = exports.ContractErrorCode = exports.IChain = exports.StreamType = exports.StreamDirection = exports.ICluster = void 0;
// Utility types
var ICluster;
(function (ICluster) {
    ICluster["Mainnet"] = "mainnet";
    ICluster["Devnet"] = "devnet";
    ICluster["Testnet"] = "testnet";
    ICluster["Local"] = "local";
})(ICluster || (exports.ICluster = ICluster = {}));
var StreamDirection;
(function (StreamDirection) {
    StreamDirection["Outgoing"] = "outgoing";
    StreamDirection["Incoming"] = "incoming";
    StreamDirection["All"] = "all";
})(StreamDirection || (exports.StreamDirection = StreamDirection = {}));
var StreamType;
(function (StreamType) {
    StreamType["All"] = "all";
    StreamType["Payment"] = "payment";
    StreamType["Vesting"] = "vesting";
    StreamType["Lock"] = "lock";
})(StreamType || (exports.StreamType = StreamType = {}));
var IChain;
(function (IChain) {
    IChain["Solana"] = "Solana";
    IChain["Aptos"] = "Aptos";
    IChain["Ethereum"] = "Ethereum";
    IChain["BNB"] = "BNB";
    IChain["Polygon"] = "Polygon";
    IChain["Sui"] = "Sui";
})(IChain || (exports.IChain = IChain = {}));
/**
 * Error codes raised by all protocols except for Solana
 */
var ContractErrorCode;
(function (ContractErrorCode) {
    /** Contract does not exist */
    ContractErrorCode["ECONTRACT_NOT_INIT"] = "ECONTRACT_NOT_INIT";
    /** Invalid total amount */
    ContractErrorCode["EBAD_AMOUNT"] = "EBAD_AMOUNT";
    /** No permissions to perform an operation */
    ContractErrorCode["ENO_PERMISSIONS"] = "ENO_PERMISSIONS";
    /** Invalid input parameters on create/update */
    ContractErrorCode["EBADINPUT"] = "EBADINPUT";
    /** Contract is already ended */
    ContractErrorCode["ECLOSED"] = "ECLOSED";
    /** Invalid amount per period input */
    ContractErrorCode["EBAD_INPUT_AMOUNT_PER_PERIOD"] = "EBAD_INPUT_AMOUNT_PER_PERIOD";
    /** Pause feature is not supported */
    ContractErrorCode["EBAD_INPUT_PAUSABLE"] = "EBAD_INPUT_PAUSABLE";
    /** Not used currently  */
    ContractErrorCode["EBAD_INPUT_UPDATE_RATE"] = "EBAD_INPUT_UPDATE_RATE";
    /** Invalid cliff amount input */
    ContractErrorCode["EBAD_INPUT_CLIFF_AMOUNT"] = "EBAD_INPUT_CLIFF_AMOUNT";
    /** Invalid period input */
    ContractErrorCode["EBAD_INPUT_PERIOD"] = "EBAD_INPUT_PERIOD";
    /** Invalid start time of the contract */
    ContractErrorCode["EBAD_INPUT_START"] = "EBAD_INPUT_START";
    /** EVM: token allowance is not enough to create a Contract */
    ContractErrorCode["EBAD_INSUFFICIENT_TOKEN_ALLOWANCE"] = "EBAD_INSUFFICIENT_TOKEN_ALLOWANCE";
    /** EVM: not enough coins were passed for withdrawal fees */
    ContractErrorCode["EBAD_INSUFFICIENT_WITHDRAWAL_FEES"] = "EBAD_INSUFFICIENT_WITHDRAWAL_FEES";
    /** Sui: Insufficient amount of tokens passed */
    ContractErrorCode["EBAD_INSUFFICIENT_AMOUNT"] = "EBAD_INSUFFICIENT_AMOUNT";
    /** Contract is already paused */
    ContractErrorCode["EPAUSED"] = "EPAUSED";
    /** Contract is not paused */
    ContractErrorCode["ENOTPAUSED"] = "ENOTPAUSED";
    /** Aptos: user opt out from direct coin transfers feature and has not coin wallet registered */
    ContractErrorCode["ENO_RECIPIENT_COIN_ADDRESS"] = "ENO_RECIPIENT_COIN_ADDRESS";
})(ContractErrorCode || (exports.ContractErrorCode = ContractErrorCode = {}));
/**
 * Error codes raised by Solana protocol specifically
 */
var SolanaContractErrorCode;
(function (SolanaContractErrorCode) {
    /** Accounts not writable */
    SolanaContractErrorCode["AccountsNotWritable"] = "AccountsNotWritable";
    /** Invalid Metadata */
    SolanaContractErrorCode["InvalidMetadata"] = "InvalidMetadata";
    /** Invalid metadata account */
    SolanaContractErrorCode["InvalidMetadataAccount"] = "InvalidMetadataAccount";
    /** Provided accounts don't match the ones in contract */
    SolanaContractErrorCode["MetadataAccountMismatch"] = "MetadataAccountMismatch";
    /** Invalid escrow account */
    SolanaContractErrorCode["InvalidEscrowAccount"] = "InvalidEscrowAccount";
    /** Provided account(s) is/are not valid associated token accounts */
    SolanaContractErrorCode["NotAssociated"] = "NotAssociated";
    /** Sender mint does not match accounts mint */
    SolanaContractErrorCode["MintMismatch"] = "MintMismatch";
    /** Recipient not transferable for account */
    SolanaContractErrorCode["TransferNotAllowed"] = "TransferNotAllowed";
    /** Contract closed */
    SolanaContractErrorCode["ContractClosed"] = "ContractClosed";
    /** Invalid Streamflow Treasury accounts supplied */
    SolanaContractErrorCode["InvalidTreasury"] = "InvalidTreasury";
    /** Given timestamps are invalid */
    SolanaContractErrorCode["InvalidTimestamps"] = "InvalidTimestamps";
    /** Invalid deposit configuration */
    SolanaContractErrorCode["InvalidDepositConfiguration"] = "InvalidDepositConfiguration";
    /** Amount cannot be zero */
    SolanaContractErrorCode["AmountIsZero"] = "AmountIsZero";
    /** Amount requested is larger than available */
    SolanaContractErrorCode["AmountMoreThanAvailable"] = "AmountMoreThanAvailable";
    /** Amount currently available is zero */
    SolanaContractErrorCode["AmountAvailableIsZero"] = "AmountAvailableIsZero";
    /** Arithmetic error */
    SolanaContractErrorCode["ArithmeticError"] = "ArithmeticError";
    /** Metadata account data must be 1104 bytes long */
    SolanaContractErrorCode["InvalidMetadataSize"] = "InvalidMetadataSize";
    /** Metadata state account must be initialized */
    SolanaContractErrorCode["UninitializedMetadata"] = "UninitializedMetadata";
    /** Authority does not have permission for this action */
    SolanaContractErrorCode["Unauthorized"] = "Unauthorized";
    /** Contract is not transferable to the original recipient */
    SolanaContractErrorCode["SelfTransfer"] = "SelfTransfer";
    /** Contract is already paused */
    SolanaContractErrorCode["AlreadyPaused"] = "AlreadyPaused";
    /** Contract is not paused */
    SolanaContractErrorCode["NotPaused"] = "NotPaused";
    /** Meta account is not rent exempt */
    SolanaContractErrorCode["MetadataNotRentExempt"] = "MetadataNotRentExempt";
})(SolanaContractErrorCode || (exports.SolanaContractErrorCode = SolanaContractErrorCode = {}));
/**
 * Error wrapper for calls made to the contract on chain
 */
var ContractError = /** @class */ (function (_super) {
    __extends(ContractError, _super);
    /**
     * Constructs the Error Wrapper
     * @param error Original error raised probably by the chain SDK
     * @param code extracted code from the error if managed to parse it
     */
    function ContractError(error, code) {
        var _this = _super.call(this, error.message) || this; // Call the base class constructor with the error message
        _this.contractErrorCode = code !== null && code !== void 0 ? code : null;
        // Copy properties from the original error
        Object.setPrototypeOf(_this, ContractError.prototype);
        _this.name = "ContractError"; // Set the name property
        _this.stack = error.stack;
        return _this;
    }
    return ContractError;
}(Error));
exports.ContractError = ContractError;
