"use strict";
var _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.SUI_MODULE_ERROR_MAP = exports.SUI_ERROR_MATCH_REGEX = exports.SUI_FEE_TABLE_IDS = exports.SUI_CONFIG_IDS = exports.SUI_PROGRAM_IDS = void 0;
var types_1 = require("../common/types");
// TODO: remove Devnet and Local addresses as they are not deployed, they are just a copy Testnet values
exports.SUI_PROGRAM_IDS = (_a = {},
    _a[types_1.ICluster.Mainnet] = "0x19007172b22fcfc00c22f1598a9cec7ab52de2b0ea0d111813bc1295a136fc10",
    _a[types_1.ICluster.Devnet] = "0xf1916c119a6c917d4b36f96ffc0443930745789f3126a716e05a62223c48993a",
    _a[types_1.ICluster.Testnet] = "0xc76590fa8711cd77b9360e00761d80cd09ceb8638e6c8ebcbcd749dcd21a9466",
    _a[types_1.ICluster.Local] = "0xf1916c119a6c917d4b36f96ffc0443930745789f3126a716e05a62223c48993a",
    _a);
exports.SUI_CONFIG_IDS = (_b = {},
    _b[types_1.ICluster.Mainnet] = "0x6cf6760b64245b8d23ef57c28ddceb6adbd540a23a509fef29b82237da4ab87b",
    _b[types_1.ICluster.Devnet] = "0x9cdb344873cd2995cab624f192fbe0b358e136c33acbdf7523916e32f24df44b",
    _b[types_1.ICluster.Testnet] = "0x9cdb344873cd2995cab624f192fbe0b358e136c33acbdf7523916e32f24df44b",
    _b[types_1.ICluster.Local] = "0x9cdb344873cd2995cab624f192fbe0b358e136c33acbdf7523916e32f24df44b",
    _b);
exports.SUI_FEE_TABLE_IDS = (_c = {},
    _c[types_1.ICluster.Mainnet] = "0xad9b75399632583fb9fcae6b5bcca34e6542ab3bedb630ecbd3f15cb1cc48dbe",
    _c[types_1.ICluster.Devnet] = "0xf3661941207b5027fb4b85a74ca5a9fd1389fb57a8f2c57bd312b950e7d48012",
    _c[types_1.ICluster.Testnet] = "0xf3661941207b5027fb4b85a74ca5a9fd1389fb57a8f2c57bd312b950e7d48012",
    _c[types_1.ICluster.Local] = "0xf3661941207b5027fb4b85a74ca5a9fd1389fb57a8f2c57bd312b950e7d48012",
    _c);
exports.SUI_ERROR_MATCH_REGEX = /MoveAbort\(MoveLocation \{ module: ModuleId \{ address: (\w+), name: Identifier\("([\w_]+)"\) }, function: \d+, instruction: \d+, function_name: Some\("([\w_]+)"\) }, (\d+)\) in command (\d+)/;
exports.SUI_MODULE_ERROR_MAP = {
    protocol: {
        1: types_1.ContractErrorCode.ECONTRACT_NOT_INIT,
        2: types_1.ContractErrorCode.EBAD_AMOUNT,
        3: types_1.ContractErrorCode.ENO_PERMISSIONS,
        4: types_1.ContractErrorCode.EBADINPUT,
        5: types_1.ContractErrorCode.ECLOSED,
        6: types_1.ContractErrorCode.EBAD_INPUT_AMOUNT_PER_PERIOD,
        8: types_1.ContractErrorCode.EBAD_INPUT_UPDATE_RATE,
        9: types_1.ContractErrorCode.EBAD_INPUT_CLIFF_AMOUNT,
        10: types_1.ContractErrorCode.EBAD_INPUT_PERIOD,
        11: types_1.ContractErrorCode.EBAD_INPUT_START,
        13: types_1.ContractErrorCode.EBAD_INSUFFICIENT_WITHDRAWAL_FEES,
        14: types_1.ContractErrorCode.EBAD_INSUFFICIENT_AMOUNT,
        15: types_1.ContractErrorCode.EPAUSED,
        16: types_1.ContractErrorCode.ENOTPAUSED,
    },
};
