"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var aptos_1 = require("aptos");
var BaseStreamClient_1 = require("../common/BaseStreamClient");
var types_1 = require("../common/types");
var constants_1 = require("./constants");
var types_2 = require("./types");
var wallet_1 = require("./wallet");
var utils_1 = require("./utils");
var constants_2 = require("../common/constants");
var AptosStreamClient = /** @class */ (function (_super) {
    __extends(AptosStreamClient, _super);
    function AptosStreamClient(clusterUrl, cluster, maxGas, programId) {
        if (cluster === void 0) { cluster = types_1.ICluster.Mainnet; }
        if (maxGas === void 0) { maxGas = "10000"; }
        var _this = _super.call(this) || this;
        _this.programId = programId ? programId : constants_1.APTOS_PROGRAM_IDS[cluster];
        _this.maxGas = maxGas;
        _this.client = new aptos_1.AptosClient(clusterUrl);
        return _this;
    }
    /**
     * Creates a new stream/vesting contract.
     */
    AptosStreamClient.prototype.create = function (streamData, _a) {
        var senderWallet = _a.senderWallet;
        return __awaiter(this, void 0, void 0, function () {
            var wallet, _b, metadataId, payload, hash;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        wallet = new wallet_1.AptosWalletWrapper(senderWallet, this.client);
                        _b = __read(this.generateMultiPayloads(__assign(__assign({}, streamData), { recipients: [__assign({}, streamData)] }), wallet)[0], 2), metadataId = _b[0], payload = _b[1];
                        return [4 /*yield*/, wallet.signAndSubmitTransaction(payload)];
                    case 1:
                        hash = _c.sent();
                        return [2 /*return*/, { ixs: [payload], txId: hash, metadataId: metadataId }];
                }
            });
        });
    };
    /**
     * Creates multiple stream/vesting contracts.
     */
    AptosStreamClient.prototype.createMultiple = function (multipleStreamData, _a) {
        var _b;
        var senderWallet = _a.senderWallet;
        return __awaiter(this, void 0, void 0, function () {
            var wallet, payloads, txs, metadatas, metadataToRecipient, errors, i, _c, metadataId, payload, recipient, hash, e_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        wallet = new wallet_1.AptosWalletWrapper(senderWallet, this.client);
                        payloads = this.generateMultiPayloads(multipleStreamData, wallet);
                        txs = [];
                        metadatas = [];
                        metadataToRecipient = {};
                        errors = [];
                        i = 0;
                        _d.label = 1;
                    case 1:
                        if (!(i < payloads.length)) return [3 /*break*/, 7];
                        _c = __read(payloads[i], 2), metadataId = _c[0], payload = _c[1];
                        recipient = multipleStreamData.recipients[i];
                        _d.label = 2;
                    case 2:
                        _d.trys.push([2, 4, 5, 6]);
                        return [4 /*yield*/, wallet.signAndSubmitTransaction(payload)];
                    case 3:
                        hash = _d.sent();
                        txs.push(hash);
                        return [3 /*break*/, 6];
                    case 4:
                        e_1 = _d.sent();
                        errors.push({
                            error: (_b = e_1 === null || e_1 === void 0 ? void 0 : e_1.toString()) !== null && _b !== void 0 ? _b : "Unknown error!",
                            recipient: recipient.recipient,
                        });
                        return [3 /*break*/, 6];
                    case 5:
                        metadatas.push(metadataId);
                        metadataToRecipient[metadataId] = recipient;
                        return [7 /*endfinally*/];
                    case 6:
                        i++;
                        return [3 /*break*/, 1];
                    case 7: return [2 /*return*/, {
                            txs: txs,
                            metadatas: metadatas,
                            metadataToRecipient: metadataToRecipient,
                            errors: errors,
                        }];
                }
            });
        });
    };
    /**
     * Attempts withdrawing from the specified stream.
     */
    AptosStreamClient.prototype.withdraw = function (_a, _b) {
        var id = _a.id, _c = _a.amount, amount = _c === void 0 ? constants_2.WITHDRAW_AVAILABLE_AMOUNT : _c;
        var senderWallet = _b.senderWallet, tokenId = _b.tokenId;
        return __awaiter(this, void 0, void 0, function () {
            var payload, wallet, hash;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        payload = {
                            type: "withdraw",
                            function: "".concat(this.programId, "::protocol::withdraw"),
                            type_arguments: [tokenId],
                            arguments: [id, amount.toString()],
                        };
                        wallet = new wallet_1.AptosWalletWrapper(senderWallet, this.client);
                        return [4 /*yield*/, wallet.signAndSubmitTransaction(payload)];
                    case 1:
                        hash = _d.sent();
                        return [2 /*return*/, { ixs: [payload], txId: hash }];
                }
            });
        });
    };
    /**
     * Attempts canceling the specified stream.
     */
    AptosStreamClient.prototype.cancel = function (cancelData, _a) {
        var senderWallet = _a.senderWallet, tokenId = _a.tokenId;
        return __awaiter(this, void 0, void 0, function () {
            var payload, wallet, hash;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        payload = {
                            type: "cancel",
                            function: "".concat(this.programId, "::protocol::cancel"),
                            type_arguments: [tokenId],
                            arguments: [cancelData.id],
                        };
                        wallet = new wallet_1.AptosWalletWrapper(senderWallet, this.client);
                        return [4 /*yield*/, wallet.signAndSubmitTransaction(payload)];
                    case 1:
                        hash = _b.sent();
                        return [2 /*return*/, { ixs: [payload], txId: hash }];
                }
            });
        });
    };
    /**
     * Attempts changing the stream/vesting contract's recipient (effectively transferring the stream/vesting contract).
     */
    AptosStreamClient.prototype.transfer = function (transferData, _a) {
        var senderWallet = _a.senderWallet, tokenId = _a.tokenId;
        return __awaiter(this, void 0, void 0, function () {
            var payload, wallet, hash;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        payload = {
                            type: "transfer",
                            function: "".concat(this.programId, "::protocol::transfer"),
                            type_arguments: [tokenId],
                            arguments: [transferData.id, transferData.newRecipient],
                        };
                        wallet = new wallet_1.AptosWalletWrapper(senderWallet, this.client);
                        return [4 /*yield*/, wallet.signAndSubmitTransaction(payload)];
                    case 1:
                        hash = _b.sent();
                        return [2 /*return*/, { ixs: [payload], txId: hash }];
                }
            });
        });
    };
    /**
     * Tops up stream account with specified amount.
     */
    AptosStreamClient.prototype.topup = function (topupData, _a) {
        var senderWallet = _a.senderWallet, tokenId = _a.tokenId;
        return __awaiter(this, void 0, void 0, function () {
            var payload, wallet, hash;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        payload = {
                            type: "topup",
                            function: "".concat(this.programId, "::protocol::topup"),
                            type_arguments: [tokenId],
                            arguments: [topupData.id, topupData.amount.toString()],
                        };
                        wallet = new wallet_1.AptosWalletWrapper(senderWallet, this.client);
                        return [4 /*yield*/, wallet.signAndSubmitTransaction(payload)];
                    case 1:
                        hash = _b.sent();
                        return [2 /*return*/, { ixs: [payload], txId: hash }];
                }
            });
        });
    };
    /**
     * Fetch stream data by its id (address).
     */
    AptosStreamClient.prototype.getOne = function (_a) {
        var _b;
        var id = _a.id;
        return __awaiter(this, void 0, void 0, function () {
            var contractResources, contract, tokenIdMatch, tokenId, data;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.client.getAccountResources(id)];
                    case 1:
                        contractResources = _c.sent();
                        contract = contractResources.find(function (r) { return r.type.includes("protocol::Contract"); });
                        if (!contract) {
                            throw new Error("Contract with id ".concat(id, " could not be found!"));
                        }
                        tokenIdMatch = contract.type.match(/0x[0-9a-f]+::protocol::Contract<(.*)>/);
                        tokenId = (_b = tokenIdMatch === null || tokenIdMatch === void 0 ? void 0 : tokenIdMatch[1]) !== null && _b !== void 0 ? _b : "";
                        data = contract.data;
                        return [2 /*return*/, new types_2.Contract(data, tokenId)];
                }
            });
        });
    };
    AptosStreamClient.prototype.get = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error("Get all method is not supported for Aptos chain!");
            });
        });
    };
    /**
     * Attempts updating the stream auto withdrawal params and amount per period
     */
    AptosStreamClient.prototype.update = function (updateData, _a) {
        var senderWallet = _a.senderWallet, tokenId = _a.tokenId;
        return __awaiter(this, void 0, void 0, function () {
            var wallet, payload, hash;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        wallet = new wallet_1.AptosWalletWrapper(senderWallet, this.client);
                        payload = {
                            type: "update",
                            function: "".concat(this.programId, "::protocol::update"),
                            type_arguments: [tokenId],
                            arguments: [
                                updateData.id,
                                updateData.enableAutomaticWithdrawal ? [true] : [],
                                updateData.withdrawFrequency ? [updateData.withdrawFrequency.toString()] : [],
                                updateData.amountPerPeriod ? [updateData.amountPerPeriod.toString()] : [],
                            ],
                        };
                        return [4 /*yield*/, wallet.signAndSubmitTransaction(payload)];
                    case 1:
                        hash = _b.sent();
                        return [2 /*return*/, { ixs: [payload], txId: hash }];
                }
            });
        });
    };
    AptosStreamClient.prototype.getFees = function (_a) {
        var address = _a.address;
        return __awaiter(this, void 0, void 0, function () {
            var resource, data, value;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.client.getAccountResource(this.programId, "".concat(this.programId, "::fees::FeeTable"))];
                    case 1:
                        resource = _b.sent();
                        data = resource.data;
                        return [4 /*yield*/, this.client.getTableItem(data.values.handle, {
                                key_type: "address",
                                key: address,
                                value_type: "u64",
                            })];
                    case 2:
                        value = _b.sent();
                        if (!value) {
                            return [2 /*return*/, null];
                        }
                        return [2 /*return*/, { streamflowFee: Number(value) / 100, partnerFee: 0 }];
                }
            });
        });
    };
    AptosStreamClient.prototype.getDefaultStreamflowFee = function () {
        return __awaiter(this, void 0, void 0, function () {
            var resource;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.getAccountResource(this.programId, "".concat(this.programId, "::admin::ConfigV2"))];
                    case 1:
                        resource = _a.sent();
                        return [2 /*return*/, Number(resource.data.streamflow_fees) / 100];
                }
            });
        });
    };
    AptosStreamClient.prototype.extractErrorCode = function (err) {
        var _a;
        return (0, utils_1.extractAptosErrorCode)((_a = err.toString()) !== null && _a !== void 0 ? _a : "Unknown error!");
    };
    /**
     * Returns StreamClient protocol program ID.
     */
    AptosStreamClient.prototype.getProgramId = function () {
        return this.programId;
    };
    AptosStreamClient.prototype.getMaxGas = function () {
        return { max_gas_amount: this.maxGas };
    };
    // Utility function to prepare transaction payloads for multiple recipients.
    AptosStreamClient.prototype.generateMultiPayloads = function (multipleStreamData, wallet) {
        var _this = this;
        return multipleStreamData.recipients.map(function (recipient) {
            var acc = new aptos_1.AptosAccount(); // Generate random address as seeds for deriving "escrow" account
            var seeds = acc.address();
            var encoder = new TextEncoder();
            // A workaround to pass a String in seeds because different wallets seem
            // to serialize vector<u8> differently and String should be safer that Uin8Array
            var actualSeeds = encoder.encode(seeds.hex());
            var metadataId = aptos_1.AptosAccount.getResourceAccountAddress(wallet.address, actualSeeds);
            return [
                metadataId.toString(),
                {
                    type: "create",
                    function: "".concat(_this.programId, "::protocol::create"),
                    type_arguments: [multipleStreamData.tokenId],
                    arguments: [
                        seeds.hex(),
                        recipient.amount.toString(),
                        multipleStreamData.period,
                        recipient.amountPerPeriod.toString(),
                        multipleStreamData.start,
                        recipient.cliffAmount.toString(),
                        multipleStreamData.cancelableBySender,
                        multipleStreamData.cancelableByRecipient,
                        multipleStreamData.transferableBySender,
                        multipleStreamData.transferableByRecipient,
                        multipleStreamData.canTopup,
                        multipleStreamData.canPause || false,
                        multipleStreamData.canUpdateRate || false,
                        multipleStreamData.automaticWithdrawal || false,
                        multipleStreamData.withdrawalFrequency || 0,
                        recipient.name,
                        recipient.recipient,
                    ],
                },
            ];
        });
    };
    return AptosStreamClient;
}(BaseStreamClient_1.BaseStreamClient));
exports.default = AptosStreamClient;
