"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultNextRequest = void 0;
var events_1 = require("events");
exports.defaultNextRequest = function () {
    var lastId = -1;
    return function () { return ++lastId; };
};
/*
** Naive Request Manager, only use 1st transport.
 * A more complex request manager could try each transport.
 * If a transport fails, or times out, move on to the next.
 */
var RequestManager = /** @class */ (function () {
    function RequestManager(transports, nextID) {
        if (nextID === void 0) { nextID = exports.defaultNextRequest(); }
        this.batch = [];
        this.batchStarted = false;
        this.lastId = -1;
        this.transports = transports;
        this.requests = {};
        this.connectPromise = this.connect();
        this.requestChannel = new events_1.EventEmitter();
        this.nextID = nextID;
    }
    RequestManager.prototype.connect = function () {
        var _this = this;
        return Promise.all(this.transports.map(function (transport) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        transport.subscribe("error", this.handleError.bind(this));
                        transport.subscribe("notification", this.handleNotification.bind(this));
                        return [4 /*yield*/, transport.connect()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }));
    };
    RequestManager.prototype.getPrimaryTransport = function () {
        return this.transports[0];
    };
    RequestManager.prototype.request = function (requestObject, notification, timeout) {
        if (notification === void 0) { notification = false; }
        return __awaiter(this, void 0, void 0, function () {
            var internalID, id, payload, result;
            var _this = this;
            return __generator(this, function (_a) {
                internalID = this.nextID().toString();
                id = notification ? null : internalID;
                payload = { request: this.makeRequest(requestObject.method, requestObject.params || [], id), internalID: internalID };
                if (this.batchStarted) {
                    result = new Promise(function (resolve, reject) {
                        _this.batch.push({ resolve: resolve, reject: reject, request: payload });
                    });
                    return [2 /*return*/, result];
                }
                return [2 /*return*/, this.getPrimaryTransport().sendData(payload, timeout)];
            });
        });
    };
    RequestManager.prototype.close = function () {
        this.requestChannel.removeAllListeners();
        this.transports.forEach(function (transport) {
            transport.unsubscribe();
            transport.close();
        });
    };
    /**
     * Begins a batch call by setting the [[RequestManager.batchStarted]] flag to `true`.
     *
     * [[RequestManager.batch]] is a singleton - only one batch can exist at a given time, per [[RequestManager]].
     *
     */
    RequestManager.prototype.startBatch = function () {
        this.batchStarted = true;
    };
    RequestManager.prototype.stopBatch = function () {
        if (this.batchStarted === false) {
            throw new Error("cannot end that which has never started");
        }
        if (this.batch.length === 0) {
            this.batchStarted = false;
            return;
        }
        this.getPrimaryTransport().sendData(this.batch);
        this.batch = [];
        this.batchStarted = false;
    };
    RequestManager.prototype.makeRequest = function (method, params, id) {
        if (id) {
            return { jsonrpc: "2.0", id: id, method: method, params: params };
        }
        return { jsonrpc: "2.0", method: method, params: params };
    };
    RequestManager.prototype.handleError = function (data) {
        this.requestChannel.emit("error", data);
    };
    RequestManager.prototype.handleNotification = function (data) {
        this.requestChannel.emit("notification", data);
    };
    return RequestManager;
}());
exports.default = RequestManager;
